import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`During the school day, students work hard.The intellectual demands made on them are great. Students and teachers work together to ensure that each student reaches their potential. A limited amount of homework is assigned to help students reinforce and consolidate their learning. Homework will be assigned during the week Monday – Thursday, with occasional projects requiring weekend work. Homework is given to meaningfully extend student learning into the home environment, to reinforce skills and to help consolidate learning. Not including reading, the target time for homework is:`}</p>
    <p>{`Grade 1 – 10 minutes`}</p>
    <p>{`Grade 2 – 20 minutes`}</p>
    <p>{`Grade 3 – 30 minutes`}</p>
    <p>{`Grade 4 – 40 minutes (including additional language assignments)`}</p>
    <p>{`Grade 5 – 50 minutes (including additional language assignments)`}</p>
    <p>{`When homework is assigned, teachers will ensure that students know what is required. It is the responsibility of students to complete the work and inform their parents it has been completed. There are a variety of ways teachers help students develop the responsibility to remember homework and what is needed to bring home. Teachers will explain their systems to families during Curriculum Afternoon in September. You are asked to support your child by checking that they have completed the assignments.`}</p>
    <p>{`As with all educational development, homework is closely linked to individual growth. Teachers are able to provide homework according to individual student needs and that supports individual progress. Grade levels will develop their own strategies for determining when homework is satisfactorily completed. Teachers will speak with students and parents so that difficulties and problems can be overcome. A child’s ability to complete assignments can be communicated to the teacher directly by email. Parent feedback on homework is welcome.`}</p>
    <p><strong parentName="p">{`It is essential that students read or are read to on a daily basis, especially in their Mother Tongue`}</strong>{`.  Reading has been identified as one of the key predictors of a student’s success in school. We cannot stress strongly enough the need for reading support and encouragement at home. For ideas on how to encourage your child to read more or how to engage your child when you read to or with them, speak with your child’s teacher.`}</p>
    <p>{`There are many ways children’s learning and academic development can be supported at home. Games, puzzles, cooking, talking, journal writing, helping plan or tracking a family trip are just a few ways this can be done.  Please speak with your child’s teachers for some specific ideas.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      